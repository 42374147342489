@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/poppins/Poppins-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Thin";
  src: url("./assets/fonts/poppins/Poppins-Thin.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Light";
  src: url("./assets/fonts/poppins/Poppins-Light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("./assets/fonts/poppins/Poppins-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./assets/fonts/poppins/Poppins-Bold.ttf") format("truetype");
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

* {
  font-family: "Poppins";
  -webkit-tap-highlight-color: transparent;
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}